import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { getUser } from '../lib/api'

const IndexPage = () => {

  const [user, setUser] = useState(null)

  useEffect(()=>{
    getUser((userData)=>{
      if (!user && userData) {
        setUser(userData)
      }
    })
  })

  return (
    <Layout style={{ marginTop: '10%' }}>
      <SEO title="Home" />
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h1>Welcome to the Wastewater Depot online CMS</h1>
            { 
              user ? (
                <p>Welcome {user.first_name}. Visit the products page to deactivate or activate products. Here you will also be able to view product specifics by clicking update. To quickly find a product, use the search box and begin typing the name of the product you desire.</p>
              ) : (
                <p>Please login to make changes</p>
              )
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
